import React from "react"
import GetStarted from '../components/GetStarted'
import Seo from "../components/Seo"
import Title from "../components/Title"
import Mission from '../components/Mission'
import WeOffer from '../components/WeOffer'
import OurTeam from '../components/OurTeam'

const About = () => {
  
  return (
    <>
      <Seo title="About" />
      <section className="about-page">
          <Title title='About Us' />
          <GetStarted />
          <Mission />
          <WeOffer />
          <OurTeam />
      </section>
    </>
  )
}



export default About
