import React from 'react'

const Mission = () => {
    return (
        <section className='section bg-pink get-started mission'>
           
            <div className='mission-items mission-title'>
                <h2 className='mission-title-text'>Our Mission</h2>
            </div>

            <div className='mission-items mission-p'>
                <p className='mission-p-text'>
                BridalGemms is committed to provide our couples with the stress free event of their dreams. To accomplish our goals to provide the best products from the best wedding and event vendors and to work with their budgets.
                </p>
            </div>

          
        </section>
    )
}

export default Mission
