import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const GetStarted = () => {
    return (
        <section className='section-center get-started section'>
            <div className='get-started-items'>
                <StaticImage
                    src='../assets/images/get-started.jpg'
                    alt='bridal gemms started photo'
                    className='get-started-img'
                    layout='constrained'
                />
            </div>

            <div className='get-started-items info-wedo'>
                <h2 className='wedo-h2'>How We Get Started</h2>
                <div className="underline-3"></div>
                <p className='wedo-p'>We started a custom made apparel with my designer brother Danny Booc in Cebu City Philippines. Since then we started expanding the business into fashion modeling and pageantry. We are now opening a sister boutique here in Tulsa Oklahoma BridalGemms is dedicated to keep the quality service and products delivering high quality with the price everyone can afford.
                </p>
            </div>
        </section>
    )
}

export default GetStarted
