import React from 'react'
import Title from "./Title"
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  {
    allTeamJson {
      nodes {
        id
        name
        position
        image {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, formats: AUTO, placeholder: BLURRED, width:350, height: 300)
          }
        }
      }
    }
  }
`

const OurTeam = () => {

    const { allTeamJson: { nodes }} = useStaticQuery(query)

    return (
        <section className="section bg-grey">
            <Title title='Our Team' />

            <div className='grid-2 section-center'>

                   {nodes.map((item) => {
                       const {id, name, position, image} = item
                       
                       return (
                           <div key={id} className='team-grid-items'>
                               <GatsbyImage 
                               image={image.childImageSharp.gatsbyImageData} 
                               
                               />
                               <h4>{name}</h4>
                               <p>{position}</p>
                           </div>
                       )
                   })}                


            </div>
        </section>
    )
}

export default OurTeam
