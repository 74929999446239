import React from 'react'
import Title from "./Title"
import { FaAward, FaRegStar  } from "react-icons/fa";
import { FiScissors } from "react-icons/fi";


const WeOffer = () => {
    return (
        <section className='section-center section'>
            <Title title='what we offer' />
            <div className='section-center grid-3'>
                <div className='grid-3-items'>
                    <FaAward className='offer-icon' size={56}/>
                    <h4 className='h4-offer'>
                    19 Years of Experience
                    </h4>
                    <p className='p-offer'>
                    We have been in this industry for 19 years of quality service. Our expertise will serve you the best value for your money.
                    </p>
                </div>

                <div className='grid-3-items'>
                    <FiScissors className='offer-icon' size={56} />
                    <h4 className='h4-offer'>
                    Custom Everything
                    </h4>
                    <p className='p-offer'>We offer the best customize wedding and event planning from ceremonial to the reception, birthday party, debutants, any events you may have we carefully execute with considerations and utmost care as if it was our own. We also provide custom and ready to wear wedding gowns, evening gowns, party dresses, casual dresses, and accessories to complete the whole ensemble.</p>
                </div>

                <div className='grid-3-items'>
                    <FaRegStar className='offer-icon' size={56}/>
                    <h4 className='h4-offer'>
                    Quality Service
                    </h4>
                    <p className='p-offer'>Our top priority is to provide the best quality serivice to all our clients.
                    </p>
                </div>
            </div>
            

        </section>
    )
}

export default WeOffer
